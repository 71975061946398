import {
  ChannelIcon,
  ChartIcon,
  ContentIcon,
  CustomersIcon,
  DashboardIcon,
  KnowledgeIcon,
  ThunderIcon,
  ToggleIcon,
  SettingsIcon
} from "@/components/custom/icons";

export interface IMenuItem {
  label: string;
  icon?: string;
  slug?: string;
  menuItems?: IMenuItem[];
}

export const bloomMenuItems: IMenuItem[] = [
  // {
  //   label: "Dashboard",
  //   slug: "/dashboard",
  //   icon: DashboardIcon,
  // },
  {
    label: "Customers",
    slug: "/customers",
    icon: CustomersIcon,
    menuItems: [
      {
        label: "Companies",
        slug: "/customers",
        menuItems: [
          {
            label: "Account Plan",
            slug: "/customers/companies/{companyId}/account-plan",
          },
          {
            label: "Insights",
            slug: "/customers/companies/{companyId}/insights",
          },
          {
            label: "Activity",
            slug: "/customers/companies/{companyId}/activities",
          },
          {
            label: "Tasks & Notes",
            slug: "/customers/companies/{companyId}/tasks-notes",
          },
          {
            label: "Contacts",
            slug: "/customers/companies/{companyId}/contacts",
          },
          {
            label: "Documents",
            slug: "/customers/companies/{companyId}/documents",
          },
        ],
      },
      // {
      //   label: "Contacts",
      //   slug: "/customers/contacts",
      //   menuItems: [
      //     {
      //       label: "Insights",
      //       slug: "/customers/contacts/{contactId}/insights",
      //     },
      //     {
      //       label: "Activity",
      //       slug: "/customers/contacts/{contactId}/activities",
      //     },
      //     {
      //       label: "Tasks & Notes",
      //       slug: "/customers/contacts/{contactId}/tasks-notes",
      //     },
      //     {
      //       label: "Documents",
      //       slug: "/customers/contacts/{contactId}/documents",
      //     },
      //   ],
      // },
      // {
      //   label: "Segments",
      //   slug: "/customers/segments",
      // },
    ],
  },
  // {
  //   label: 'Actions',
  //   slug: '/actions',
  //   icon: ThunderIcon
  // },
  // {
  //   label: 'Performance',
  //   slug: '/performance',
  //   icon: ChartIcon
  // },
  // {
  //   label: 'Horizontal1'
  // },
  // {
  //   label: "Channels",
  //   slug: "/channels",
  //   icon: ChannelIcon,
  //   menuItems: [
  //     {
  //       label: "All",
  //       slug: "/channels",
  //     },
  //     {
  //       label: "Data Warehouse",
  //       slug: "/channels/data-warehouse",
  //     },
  //     {
  //       label: "Cloud Apps",
  //       slug: "/channels/cloud-apps",
  //     },
  //     {
  //       label: "AI/ML",
  //       slug: "/channels/machine-learning",
  //     },
  //   ],
  // },
  // {
  //   label: "Knowledge Base",
  //   slug: "/knowledge-base",
  //   icon: KnowledgeIcon,
  // },
  {
    label: "Performance",
    slug: "/retool/dashboard",
    icon: ChartIcon,
  },
  // {
  //   label: 'Content',
  //   slug: '/content',
  //   icon: ContentIcon,
  //   menuItems: [
  //     {
  //       label: 'All',
  //       slug: '/content'
  //     },
  //     {
  //       label: 'Emails',
  //       slug: '/content/emails'
  //     },
  //     {
  //       label: 'Landing Pages',
  //       slug: '/content/landing-pages'
  //     },
  //     {
  //       label: 'Text Messages',
  //       slug: '/content/text-messages'
  //     }
  //   ]
  // },
  // {
  //   label: 'Components',
  //   slug: '/components',
  //   icon: KnowledgeIcon
  // }
  {
    label: 'Horizontal1'
  },
  {
    label: 'Settings',
    slug: '/settings',
    icon: SettingsIcon
  }
];

export const DEFAULT_PAGE_SIZE = 14;

export const getCurrentSubMenuItems = (pathname: string) => {
  if (pathname.startsWith("/customers/companies/")) {
    return bloomMenuItems[0].menuItems?.at(0)?.menuItems;
  }
  if (pathname.startsWith("/customers/contacts/")) {
    return bloomMenuItems[1].menuItems?.at(1)?.menuItems;
  }
  return bloomMenuItems.find(
    (menuItem) => menuItem.slug && pathname.includes(menuItem.slug || "")
  )?.menuItems;
};

export const getPageTitle = (pathname: string) => {
  return bloomMenuItems.find(
    (menuItem) => menuItem.slug && pathname.includes(menuItem.slug || "")
  )?.label;
};

export const Environment = {
  API_URL: `${import.meta.env.VITE_API_URL}/api`,
  WS_API_URL: import.meta.env.VITE_WS_API_URL,
  AUTH0_DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: import.meta.env.VITE_AUTH0_CLIENT_ID,
  AGENT_ID: "5bc2439d-c2a0-4134-a82f-f1062b230158", // Sales agent
  KNOWLEDGE_BASE_ID: "65ed2aaf-f766-40f5-863d-025ae71f2278",
  CONVERSATION_ID: "c9f4a949-b843-4b66-9cb4-b8c5a3a85501",
  BEE_PLUGIN_CLIENT_ID: import.meta.env.VITE_BEE_PLUGIN_CLIENT_ID,
  BEE_PLUGIN_CLIENT_SECRET: import.meta.env.VITE_BEE_PLUGIN_CLIENT_SECRET,
  S3_STORAGE_URL: import.meta.env.VITE_S3_STORAGE_URL,
  GOOGLE_CLIENT_ID: import.meta.env.VITE_GOOGLE_CLIENT_ID
};
