import { apiClient } from "@/services/api";
import { useState } from "react";
import { GoogleIcon, SlackIcon } from "./icons";

export const ComposioGmailConnectButton = () => {
  const [isConnecting, setConnecting] = useState(false);
  
  const onConnect = async () => {
    setConnecting(true);
    try {
      const { data } = await apiClient.post('/composio/connect/google', { redirectUrl: location.href });
      const { redirectUrl, connectionStatus, connectedAccountId } = data;
      if (redirectUrl)
        location.href = redirectUrl;
      else if (connectionStatus === 'ACTIVE') {
        alert("Already connected to Composio Gmail");
      }
    } catch (err) {
      console.error("@Error while connecting to Composio Gmail: ", err);
    }
    setConnecting(false);
  }

  return (
    <button className="px-4 py-2 border flex items-center gap-2 border-slate-200 dark:border-slate-700 rounded-lg text-slate-700 dark:text-slate-200 hover:border-slate-400 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 hover:shadow transition duration-150" onClick={onConnect} disabled={isConnecting}>
      <img className="w-6 h-6" src={GoogleIcon} loading="lazy" alt="google logo" />
      <span className='font-firstext'>Connect Gmail</span>
    </button>
  );
};

export const ComposioSlackConnectButton = () => {
  const [isConnecting, setConnecting] = useState(false);
  
  const onConnect = async () => {
    setConnecting(true);
    try {
      const { data } = await apiClient.post('/composio/connect/slack', { redirectUrl: location.href });
      const { redirectUrl, connectionStatus, connectedAccountId } = data;
      if (redirectUrl)
        location.href = redirectUrl;
      else if (connectionStatus === 'ACTIVE') {
        alert("Already connected to Composio Slack");
      }
    } catch (err) {
      console.error("@Error while connecting to Composio Slack: ", err);
    }
    setConnecting(false);
  }

  return (
    <button className="px-4 py-2 border flex items-center gap-2 border-slate-200 dark:border-slate-700 rounded-lg text-slate-700 dark:text-slate-200 hover:border-slate-400 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 hover:shadow transition duration-150" onClick={onConnect} disabled={isConnecting}>
      <img className="w-6 h-6" src={SlackIcon} loading="lazy" alt="google logo" />
      <span className='font-firstext'>Connect Slack</span>
    </button>
  );
};